<template>
  <div class="charecteristc main-block">
    <div class="main-block__head">
      <h1 class="main-block__title">Характеристики</h1>
    </div>
    <div class="charecteristc__block  ">
      <ul
        v-if="allCarsData.characteristics && allCarsData.characteristics.length"
        class="charecteristc__items"
      >
        <li class="charecteristc__item flex">
          <p class="charecteristc__key">
            Ш х Д х В
            <b-icon class="ml-0.5" icon="info-circle" font-scale="1" style="color: #acacac" />
          </p>
          <div class="flex">
            <p class="charecteristc__value">
              {{ characteristics('size').split("x")[0] }} см
            </p>
            <p class="charecteristc__key">х</p>
            <p class="charecteristc__value">
              {{ characteristics('size').split("x")[1] }} см
            </p>
            <p class="charecteristc__key">х</p>
            <p class="charecteristc__value">
              {{ characteristics('size').split("x")[2] }} см
            </p>
          </div>
        </li>
        <li class="charecteristc__item flex">
          <p class="charecteristc__key">Грузоподъемность</p>
          <p class="charecteristc__value">{{ characteristics('capacity') + characteristics('capacity', 1) }} т</p>
        </li>
        <li class="charecteristc__item flex">
          <p class="charecteristc__key">Объем(куб.м)</p>
          <p class="charecteristc__value">{{ characteristics('volume') + characteristics('volume', 1) }} м3</p>
        </li>
        <li class="charecteristc__item flex">
          <p class="charecteristc__key">Кол. мест</p>
          <p class="charecteristc__value">{{ characteristics('places') + characteristics('places', 1) }} коробок</p>
        </li>
        <li class="charecteristc__item flex">
          <p class="charecteristc__key">Снаряжная масса</p>
          <p class="charecteristc__value">{{ characteristics('weight') + characteristics('weight', 1) }} т</p>
        </li>
        <li class="charecteristc__item flex">
          <p class="charecteristc__key">Количество посадочных мест</p>
          <p class="charecteristc__value">
            {{ characteristics('seats') }}
          </p>
        </li>
        <li class="charecteristc__item flex"></li>
        <li v-for="item in allCarsData.documents" :key="item.id" class="document__item flex">
          <img class="document__img" :src="$url + '/' + item.path" alt="" />
          <!-- <button class="document__btn">
            <a :href="$url + '/' + item.path" @click.prevent="downloadItem(item)">
              <b-icon icon="arrow-down" scale="0.7" color="white" />
            </a>
          </button> -->
          <div class="document__info">
            <p class="document__name">
              <a :href="$url + '/' + item.path" target="_blank">
                {{ name(item.title) }}
              </a>
            </p>
            <p class="document__text">
              <span v-if="item.size">{{ item.size ?? '' }} MB</span>
            </p>
          </div>
          <div class="document__date-box">
            <p class="document__text">
              {{ new Date(item.created_at).toLocaleTimeString('ru-RU', {hour: '2-digit',minute: '2-digit'}) }}
            </p>
            <label class="document__date" for="">
              {{ new Date(item.created_at).toLocaleDateString('ru-RU', { day:"numeric", month:"long"}) }}
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import axios from 'axios';

export default {
  name: "SwiperExampleThumbsGallery",
  title: "Thumbs gallery with Two-way control",
  props: {
    allCarsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showImage: false,
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop?.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs?.$swiper;
      if (swiperTop) {
        swiperTop.controller.control = swiperThumbs;
        swiperThumbs.controller.control = swiperTop;
      }
    });
  },
  methods: {
    name(value) {
      let name = value.split("/");
      return name[name.length - 1];
    },
    downloadItem ({ path, title }) {
    axios.get(
      process.env.VUE_APP_PROXY + '/' + path, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = title
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    },
    characteristics(type, car = null) {
      if (car) {
        if (this.allCarsData.trailer && this.allCarsData.trailer.characteristics.length) {
          return this.allCarsData.trailer.characteristics[0][type];
        }
        else return 0;
      }

      return this.allCarsData.characteristics[0][type]
    }
  },
  computed: {
    
  }
};
</script>

<style scoped>
.charecteristc__block {
  align-items: flex-start;
}
.charecteristc__items {
  /* width: 48%; */
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3px 0 0 0;
}
.charecteristc__item {
  padding-top: 11.5px;
  height: 27.5px;
}
.charecteristc__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}
.charecteristc__value {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #000000;
}
.charecteristc__key {
  margin: 0 3px;
}
.bg-inherit {
  background: inherit;
}
.document__item {
  padding-top: 15px;
  position: relative;
}
.document__item:not(:last-child) {
  padding-bottom: 9px;
  border-bottom: 1px solid #f2f2f8;
}
.document__img {
  width: 100%;
  max-width: 30px;
  height: 30px;
  background: #83b1f4;
  border-radius: 5px;
  margin-right: 10px;
}
.document__btn {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #707070;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  border-radius: 4px;
  position: absolute;
  left: 7px;
}
.document__info {
  width: 65%;
  word-break: break-all;
}
.document__name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 2px;
}
.document__text {
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #acacac;
}
.document__date-box {
  width: 30%;
  text-align: right;
}
.document__date {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #707070;
  margin: 2px 0 0 0;
}
</style>
